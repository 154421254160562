import InboxIcon from '@mui/icons-material/Inbox';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import usePatientSupportDashboardViewModel from './viewModel';

export const dataTableHeaders = [
  {
    displayName: 'Name',
    sortable: true,
    key: 'fullName',
    showOnarchieve: true,
    showOninbox: true,
  },
  {
    displayName: 'Phone Number',
    sortable: false,
    key: 'contactNumber',
    showOnarchieve: true,
    showOninbox: true,
  },
  {
    displayName: 'Email Address',
    sortable: false,
    key: 'email',
    showOnarchieve: true,
    showOninbox: true,
  },
  {
    displayName: 'Date Registered',
    sortable: true,
    key: 'createdAt',
    showOnarchieve: true,
    showOninbox: true,
  },
  {
    displayName: 'Contacted',
    sortable: false,
    key: 'button',
    showOnarchieve: false,
    showOninbox: true,
  },
  {
    displayName: 'Archived by',
    sortable: false,
    key: 'dateArchived',
    showOnarchieve: true,
    showOninbox: false,
  },
  {
    displayName: 'Date Archived',
    sortable: true,
    key: 'dateArchived',
    showOnarchieve: true,
    showOninbox: false,
  },
];

export const sideBarMenu = [
  {
    id: 1,
    key: 'inbox',
    name: 'Inbox',
    icon: <InboxIcon sx={{ fontSize: '24px' }} />,
    onClick: () => {},
  },
  {
    id: 2,
    key: 'archive',
    name: 'Archive',
    icon: <ArchiveOutlinedIcon sx={{ fontSize: '24px' }} />,
    onClick: () => {},
  },
];
